import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import { Skeleton } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'
import { useFormik } from 'formik'
import { Button, TextField } from '@material-ui/core'

import { IdList } from '../../../core/utils/id-list'
import { selectUser } from '../../../user/selectors'
import { GET_USER_INFO } from '../../../user/actionTypes'
import {
  getCampaignFeaturedList,
  getCampaignList,
  participatePromotion,
  getCampaignStatuses
} from '../../../campaign/actions'
import {
  getUserInfo,
  getUserOfferStatus,
  agreeTerms
} from '../../../user/actions'
import { selectIsActionLoaded, selectConfig } from '../../../config/selectors'
import BaseDrawer from '../../BaseDrawer/BaseDrawer'
import { RedemptionCenterCondition } from '../../../offer/redemptionCenterCondition'
import LoadingDialog from '../../LoadingDialog/LoadingDialog'
import { toggleWelcome } from '../../../config/actions'
import { getOfferList } from '../../../offer/actions'
import _ from 'lodash'
import arrowImg from '../../../../assets/images/right-arrow.png'
import ReactMarkdown from 'react-markdown'
import DynamicIcon from '../../../ui/customIcons/DynamicIcon/DynamicIcon'
import moment from 'moment-timezone'
import earnImg from '../../../../assets/images/campaign/referralPoint.png'
import * as REFERRAL_CONSTANTS from './InviteFormConstants'
import PointFromReferral from '../../../user/PointsFromReferral/PointsFromReferral'

import './InviteForm.scss'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'

interface InviteFormProps {
  btnTxt: string
  handleSubmit?: () => Promise<void>
  label?: string
  labelHint?: string
}

const InviteForm: FC<InviteFormProps> = ({
  btnTxt,
  handleSubmit,
  label = '',
  labelHint = ''
}) => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const dispatch = useDispatch()
  const user: UserState = useSelector(selectUser)
  const config: ConfigState = useSelector(selectConfig)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const isUserInfoLoaded = useSelector<boolean>(
    selectIsActionLoaded(GET_USER_INFO)
  )
  const initialValues: ParticipatePromotion = {
    inviteCode: ''
  }
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSpinner, setIsSpinner] = useState(false)
  const addNewCodeButtonRef = useRef(null)
  const [isNewCodeOpen, setNewCodeOpen] = useState(false)
  const [isCodeDetailOpen, setCodeDetailOpen] = useState(false)
  const [isReferralCodeDetailOpen, setReferralCodeDetailOpen] = useState(false)

  const [currentCodeCampName, setCodeCampName] = useState('')
  const [currentCodeCampDesc, setCodeCampDesc] = useState('')
  const [currentCode, setCode] = useState('')
  const [currentDate, setDate] = useState('')
  const [currentTime, setTime] = useState('')
  const [codeType, setCodeType] = useState('')
  const [isActionComplete, setIsActionComplete] = useState(false)

  const {
    EVENT_LIFE_TIME_ERROR_CODE,
    EVENT_TIME_FRAME_ERROR_CODE,
    EVENT_LIFE_TIME_ERROR,
    EVENT_TIME_FRAME_ERROR
  } = REFERRAL_CONSTANTS

  const hasInviteCodes = (): boolean => {
    if (isUserInfoLoaded !== false && user.usedInviteCodes.length > 0) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (hasInviteCodes()) {
      trackEvent({
        action: 'Invite Code View',
        page: 'Invite Code',
        payload: {
          status: 'applied'
        }
      })
    } else {
      trackEvent({
        action: 'Invite Code View',
        page: 'Rewards Code',
        payload: {
          status: 'empty'
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (codeType !== null && codeType.length > 0) {
      trackEvent({
        action: 'Input Invite Code',
        page: 'Invite Code',
        payload: {
          type: codeType,
          description: 'The type of code the user entered on the input.'
        }
      })
    }
  }, [codeType, trackEvent])
  const trackAddNewCode = (): void => {
    trackEvent({
      action: 'Add New Code',
      page: 'Invite Code',
      payload: {
        origin: 'Add New Code'
      }
    })
  }

  const trackLimit = (errorCode: number, value: string): void => {
    if (
      errorCode === EVENT_LIFE_TIME_ERROR_CODE ||
      errorCode === EVENT_TIME_FRAME_ERROR_CODE
    ) {
      trackEvent({
        page: AnalyticsCategory.Referral,
        action:
          errorCode === EVENT_LIFE_TIME_ERROR_CODE
            ? EVENT_LIFE_TIME_ERROR
            : EVENT_TIME_FRAME_ERROR,
        payload: {
          title: 'referralCode',
          value,
          description: 'The code that the user tried to enter to Rewards'
        }
      })
    }
  }
  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (formData: ParticipatePromotion, action) => {
      if (formData.inviteCode !== '') {
        setIsSpinner(true)
        dispatch(participatePromotion(formData))
          .then((res: ParticipatePromotionAction) => {
            action.resetForm()
            setErrorMessage('')
            setNewCodeOpen(false)
            if (res.payload !== null) {
              if (
                !_.isEmpty(res.payload.WelcomeTitle) &&
                !_.isEmpty(res.payload.WelcomeButtonLabel)
              ) {
                dispatch(toggleWelcome(true))
              }
              if (res.payload.CampaignType != null) {
                if (res.payload.CampaignType === REFERRAL_CONSTANTS.REFERRAL) {
                  setCodeType(REFERRAL_CONSTANTS.REFERRAL)
                } else {
                  setCodeType(REFERRAL_CONSTANTS.PROMOTIONAL)
                }
              } else {
                setCodeType(REFERRAL_CONSTANTS.PROMOTIONAL)
              }
            }
          })
          .then(async () => {
            const promiseList: any[] = [
              dispatch(agreeTerms()),
              dispatch(getCampaignList()),
              dispatch(getCampaignFeaturedList()),
              dispatch(getCampaignStatuses()),
              dispatch(getOfferList()),
              dispatch(getUserOfferStatus())
            ]

            return await Promise.all(promiseList)
          })
          .then(async () => {
            await dispatch(getUserInfo())
            if (handleSubmit != null) {
              return await handleSubmit()
            }
          })
          .catch((error: AxiosResponse) => {
            if (error != null) {
              if (error.data != null) {
                if (error.data.error_description != null) {
                  setErrorMessage(t([error.data.error_description]))
                  trackLimit(error.data.error, formData.inviteCode)
                }
              }
            } else {
              setErrorMessage(t`InvalidCode`)
            }
            Sentry.captureException(error)
            setIsSpinner(false)
          })
      }
    }
  })
  const disableBtn = (value: string): void => {
    setIsBtnDisabled(value.length < 3)
  }

  const handleCloseAddNewCode = (): void => {
    setNewCodeOpen(false)
  }
  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    disableBtn(event.target.value)
    setErrorMessage('')
    form.handleChange(event)
  }
  const handleCodeChangeForDrawer = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    disableBtn(event.target.value)
    setErrorMessage('')
    form.handleChange(event)
  }

  const handleKeyPress = (event: KeyboardEvent): void => {
    if (hasInviteCodes()) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  const submitTheForm = async (event: any): Promise<any> => {
    event.preventDefault()
    await form.submitForm()
  }

  const codeButtonDetail = (code: string, actionStatus: string, date: string, time: string): any => {
    return (
      <>
        <div className='promotion-info'>
          <p className='promotion-info__upper'>
            {code.toUpperCase()}
            <span className='promotion-action'>{actionStatus}</span>
          </p>
          <p className='promotion-info__below'>{date + ' - ' + time}</p>
        </div>
        <div className='promotion-item-arrow'>
          <img src={arrowImg} className='arrow-img' alt='Right' />
        </div>
      </>
    )
  }

  const referralCampaignDetail = (): any => {
    let title = t`WellDone`
    let detail = t`DetailActionComplete`
    if (!isActionComplete) {
      title = t`GettingReward`
      detail = t`DetailActionRequire`
    }
    return (
      <>
        <div className='referral-detail-drawer'>
          <div>
            <img
              src={earnImg}
              className='referral-detail-drawer__image'
              alt='Promo Code'
            />
          </div>
          <div>
            <p className='referral-detail-drawer__title'>{title}</p>
          </div>

          <div className='referral-detail-drawer__details'>
            <p>
              {detail}
            </p>
          </div>
          <Button
            type='button'
            className='referral-detail-drawer__button'
            onClick={() => setReferralCodeDetailOpen(false)}
          >
            {t`Ok`}
          </Button>
        </div>
      </>
    )
  }

  const submitButtonRef = useRef(null)
  const addNewButtonRef = useRef(null)

  const renderForm = (): any => {
    return (
      <>
        <form className='invite-form'>
          <TextField
            type='text'
            name='inviteCode'
            value={form.values.inviteCode}
            variant='outlined'
            className={`form-control invite-form__input ${
              form.values.inviteCode === null ? '' : 'invite-form__input--fill'
            }`}
            placeholder={t`EnterCode`}
            onChange={handleCodeChange}
            onKeyPress={handleKeyPress}
            onFocus={() => {
              ;(submitButtonRef?.current as any).scrollIntoView()
            }}
            error={errorMessage !== ''}
            helperText={errorMessage}
          />
          <Button
            id={IdList.inviteCode}
            type='submit'
            className='btn invite-form__btn'
            classes={{ disabled: 'btn-disable' }}
            disabled={isBtnDisabled}
            ref={submitButtonRef}
            onClick={submitTheForm}
            onTouchEnd={submitTheForm}
          >
            {btnTxt}
          </Button>
        </form>
      </>
    )
  }

  const codeList = (): any => {
    const sortedCodes = _.orderBy(user.usedInviteCodes, 'CreatedAtUTC', 'desc')
    const codes = _.map(sortedCodes, (codeObj) => {
      let codeTime = moment.utc(codeObj.CreatedAtUTC)
      if (config.IANATimezone != null && config.IANATimezone !== '') {
        codeTime = codeTime.tz(config.IANATimezone)
      }
      const date = codeTime.format('MMM Do, YYYY')
      const time = codeTime.format('hh:mm a')
      let actionRequired = ''
      if (codeObj.IsReferralCampaign) {
        if (!codeObj.IsActionComplete) {
          actionRequired = ' (' + t`ActionRequired` + ')'
        }
        const completeStatus = (
          <div
            className='promotion-item'
            key={codeObj.PromotionCode}
            onClick={() => {
              setReferralCodeDetailOpen(true)
              setIsActionComplete(true)
            }}
          >
            {codeButtonDetail(codeObj.PromotionCode, actionRequired, date, time)}
          </div>
        )
        if (codeObj.IsActionRequired) {
          if (!codeObj.IsActionComplete) {
            return (
              <div
                className='promotion-item'
                key={codeObj.PromotionCode}
                onClick={() => {
                  setReferralCodeDetailOpen(true)
                }}
              >
                {codeButtonDetail(codeObj.PromotionCode, actionRequired, date, time)}
              </div>
            )
          } else {
            return completeStatus
          }
        } else {
          return completeStatus
        }
      } else {
        return (
          <div
            className='promotion-item'
            key={codeObj.PromotionCode}
            onClick={() => {
              setCodeCampName(codeObj.CampaignName)
              setCodeCampDesc(codeObj.CampaignDescription)
              setCode(codeObj.PromotionCode)
              setDate(date)
              setTime(time)
              setCodeDetailOpen(true)
            }}
          >
            {codeButtonDetail(codeObj.PromotionCode, actionRequired, date, time)}
          </div>
        )
      }
    })
    return (
      <>
        <div className='promo-list-header'>
          <p className='list-label'>{t`MyPromoCodes`}</p>
          <Button
            ref={addNewCodeButtonRef}
            id={IdList.addNewCode}
            className='add-new-code-button'
            onClick={() => {
              form.resetForm()
              setErrorMessage('')
              setNewCodeOpen(true)
              trackAddNewCode()
            }}
          >
            {t`AddNewCode`}&nbsp;
          </Button>
        </div>
        <div className='promo-codes'>{codes}</div>
      </>
    )
  }
  return (
    <>
      {hasInviteCodes() ? (
        codeList()
      ) : isUserInfoLoaded === false ? (
        <div className='invite-form'>
          <Skeleton
            variant='rect'
            classes={{ root: 'invite-form__btn--loading' }}
          />
          <Skeleton
            variant='rect'
            classes={{ root: 'invite-form__btn--loading' }}
          />
        </div>
      ) : (
        renderForm()
      )}

      <BaseDrawer
        isOpen={isSpinner}
        isCentered
        centerCondition={RedemptionCenterCondition.both}
      >
        <LoadingDialog isSpinner={isSpinner} />
      </BaseDrawer>
      <BaseDrawer
        isOpen={isNewCodeOpen}
        onClose={handleCloseAddNewCode}
        title={t`AddNewPromoCode`}
        isFromDesktopTransactionHistory={matchesMd}
      >
        <div className='add-new-promo-drawer'>
          <form className='add-new-promo-drawer__form-control'>
            <label className='add-new-promo-drawer__form-control__label'>{t`EnterYourInviteCode`}</label>
            <TextField
              type='text'
              name='inviteCode'
              value={form.values.inviteCode}
              variant='outlined'
              className='add-new-promo-drawer__add-new-form'
              placeholder={t`EnterCode`}
              onChange={handleCodeChangeForDrawer}
              onFocus={() => {
                ;(addNewButtonRef?.current as any).scrollIntoView()
              }}
              error={errorMessage !== ''}
              helperText={errorMessage}
            />

            <Button
              id={IdList.addNewCodeSubmit}
              type='submit'
              className='add-new-promo-drawer__btn-active btn'
              classes={{ disabled: 'btn-disable' }}
              disabled={isBtnDisabled}
              ref={addNewButtonRef}
              onClick={submitTheForm}
              onTouchEnd={submitTheForm}
            >
              {t`ApplyCode`}
            </Button>
          </form>
        </div>
      </BaseDrawer>

      <BaseDrawer
        isOpen={isCodeDetailOpen}
        onClose={() => setCodeDetailOpen(false)}
        customMargin={
          matchesMd ? REFERRAL_CONSTANTS.INVITE_FORM_DRAWERS_CUSTOM_MARGIN : ''
        }
      >
        <div className='promo-detail-drawer'>
          <div>
            <span className='promo-detail-drawer__title'>
              {currentCodeCampName}
            </span>
          </div>

          <div className='promo-detail-drawer__details'>
            <span className='promo-detail-drawer__timer'>
              <DynamicIcon
                iconName='InfoOutlined'
                className='icon__ico m-right-3'
              />
              <span>{currentCode.toUpperCase()}</span>
            </span>
            <span className='promo-detail-drawer__timer'>
              <DynamicIcon
                iconName='InsertInvitation'
                className='icon__ico m-right-3'
              />
              <span>{currentDate}</span>
            </span>
            <span className='promo-detail-drawer__timer'>
              <DynamicIcon
                iconName='AccessTimeOutlined'
                className='icon__ico m-right-3'
              />
              <span>{currentTime}</span>
            </span>
          </div>

          <div className='promo-detail-drawer__info'>
            <ReactMarkdown linkTarget='_blank'>
              {currentCodeCampDesc}
            </ReactMarkdown>
          </div>
        </div>
      </BaseDrawer>
      <BaseDrawer
        isOpen={isReferralCodeDetailOpen}
        onClose={() => setReferralCodeDetailOpen(false)}
        customMargin={
          matchesMd ? REFERRAL_CONSTANTS.INVITE_FORM_DRAWERS_CUSTOM_MARGIN : ''
        }
      >
        {referralCampaignDetail()}
      </BaseDrawer>
      <PointFromReferral />
    </>
  )
}

export default InviteForm
