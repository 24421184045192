import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ArrowForward, Loop } from '@material-ui/icons'

import SectionTitle from '../../SectionTitle/SectionTitle'
import { selectUpdateCardList } from '../selectors'
import UpdateCardDrawer from '../Drawer/UpdateCardDrawer'

import './UpdateCardList.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'

const UpdatedCampaigns: FC = () => {
  const { t } = useTranslation()
  const updateCardList = useSelector(selectUpdateCardList)
  const [selectedUpdateCard, setSelectedUpdateCard] =
    useState<null | UpdateCard>(null)
  const { trackEvent } = useTracking()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const handleClick = (card: UpdateCard): void => {
    trackEvent({
      action: 'Update Detail View',
      category: 'Updates',
      payload: {
        updateCardID: card.UpdateCardID
      }
    })

    setSelectedUpdateCard(card)
  }

  const singleUpdateCard = updateCardList.length === 1
  return (
    <div>
      {updateCardList.length > 0 && (
        <div className='update-cards-container'>
          <div>
            <SectionTitle title={t`Updates`} Icon={Loop} />
          </div>
          <div
            className={`update-cards ${
              !matchesMd && singleUpdateCard ? 'overflow-hid' : ''
            }`}
          >
            {updateCardList.map((updateCard) => (
              <div
                onClick={() => handleClick(updateCard)}
                key={updateCard.UpdateCardID}
                className={`update-card ${
                  singleUpdateCard ? 'update-card-single' : ''
                }`}
              >
                <div
                  className={`update-card-texts ${
                    updateCard.ImageUrl == null ? 'w-95' : ''
                  }`}
                >
                  <div className='subheader1'>{updateCard.SubHeader1}</div>
                  <div className='update-card-title'>
                    <span className='update-card-title-text'>
                      {updateCard.Title}
                    </span>
                    <span>
                      <ArrowForward />
                    </span>
                  </div>
                  <div className='subheader2'>{updateCard.SubHeader2}</div>
                </div>
                {updateCard.ImageUrl != null && (
                  <img
                    className='update-card-image'
                    src={updateCard.ImageUrl}
                    alt='Update Card'
                  />
                )}
              </div>
            ))}
          </div>

          {selectedUpdateCard != null && (
            <UpdateCardDrawer
              updateCard={selectedUpdateCard}
              setSelectedUpdateCard={setSelectedUpdateCard}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default UpdatedCampaigns
