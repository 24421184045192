import React, { ReactElement } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import './InvalidCredentialsDialog.scss'
import { useTranslation } from 'react-i18next'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

interface InvalidCredentialsDialogProps {
  open: boolean
  handleClose: () => void
  handleSignUpClick: () => void
}

const InvalidCredentialsDialog = ({
  open,
  handleClose,
  handleSignUpClick
}: InvalidCredentialsDialogProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='invalid-credentials-dialog'
      maxWidth='xs'
    >
      <ErrorOutlineOutlinedIcon className='info-icon' color='error' fontSize='large' />
      <DialogTitle className='title'>
        <button onClick={handleClose} aria-label='Close' className='close'>
          <CloseOutlinedIcon />
        </button>
        {t`InvalidCredentialsTitle`}
      </DialogTitle>
      <DialogContent className='content'>
        {t`InvalidCredentialsMsg`}
      </DialogContent>
      <DialogActions>
        <Button id='invalid-credentials' className='btn dialog-btn' onClick={handleSignUpClick}>
          {t`SignUp`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InvalidCredentialsDialog
