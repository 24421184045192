import React, { FC } from 'react'

import BaseDrawer from '../BaseDrawer/BaseDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsWelcomeMessage } from '../../config/selectors'
import { RedemptionCenterCondition } from '../../offer/redemptionCenterCondition'
import { selectCampaignPromotion } from '../../campaign/selectors'
import { toggleWelcome } from '../../config/actions'
import ReactMarkdown from 'react-markdown'

import './WelcomeMessage.scss'
import _ from 'lodash'

const WelcomeMessage: FC = () => {
  const dispatch = useDispatch()
  const campaignPromo = useSelector(selectCampaignPromotion)
  const isWelcome = useSelector(selectIsWelcomeMessage)

  const onCloseMsg = (): void => {
    dispatch(toggleWelcome(false))
  }

  return (
    <BaseDrawer
      isOpen={isWelcome}
      isCentered
      onClose={onCloseMsg}
      centerCondition={RedemptionCenterCondition.both}
    >
      <div className='welcome-msg'>
        {!_.isEmpty(campaignPromo.welcomeIcon) && (
          <img
            src={`${campaignPromo.welcomeIcon as string}`}
            className='welcome-msg__icon'
            alt='Welcome Message Icon'
          />
        )}
        <div className='welcome-msg__title'>{campaignPromo.WelcomeTitle}</div>
        <div className='welcome-msg__description'>
          <ReactMarkdown linkTarget='_blank'>
            {campaignPromo.WelcomeDescription}
          </ReactMarkdown>
        </div>
        <div className='welcome-msg__btn btn' onClick={onCloseMsg}>
          {campaignPromo.WelcomeButtonLabel}
        </div>
      </div>
    </BaseDrawer>
  )
}

export default WelcomeMessage
