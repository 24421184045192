import React, { ReactElement } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import './FailedAttemptsLeftDialog.scss'

interface FailedAttemptsLeftDialogProps {
  open: boolean
  handleClose: () => void
  handleButtonClick: () => void
  title: string
  content: string
  buttonLabel: string
}

const FailedAttemptsLeftDialog = ({
  open,
  handleClose,
  handleButtonClick,
  title,
  content,
  buttonLabel
}: FailedAttemptsLeftDialogProps): ReactElement => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='failed-attempts-left-dialog'
      maxWidth='xs'
    >
      <ErrorOutlineOutlinedIcon className='info-icon' color='error' fontSize='large' />
      <DialogTitle className='title'>
        {title}
        <CloseOutlinedIcon onClick={handleClose} aria-label='close' className='close' />
      </DialogTitle>
      <DialogContent className='content'>
        {content}
      </DialogContent>
      <DialogActions>
        <Button id='failed-attempts-btn' className='btn dialog-btn' onClick={handleButtonClick}>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FailedAttemptsLeftDialog
