import React, { ReactElement, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import '../SignUp.scss'
import './AdditionalInformationForm.scss'
import { PASSWORD, RESET_PASSWORD_FIELDS, TEXT } from '../../constants'
import FormTextField from '../FormTextField'
import {
  showPasswordToggleIcon,
  validateEmail,
  validateName,
  validatePassword
} from '../../utils'

interface AdditionalInformationFormProps {
  customTheme: { [key: string]: string }
  handleAdditionalInfoSubmit: (info: SignUpForm) => void
  handleDiscard: () => void
  isMobile?: boolean
  errorDisplay?: ReactElement | null
}

const AdditionalInformationForm = ({
  customTheme,
  handleAdditionalInfoSubmit,
  handleDiscard,
  isMobile = false,
  errorDisplay
}: AdditionalInformationFormProps): ReactElement => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false)
  const initialValues: SignUpForm = {
    name: '',
    email: '',
    password: '',
    confirmNewPassword: ''
  }
  const i18t = useTranslation()

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formData: SignUpForm) => {
      handleAdditionalInfoSubmit(formData)
    },
    validate: (values) => {
      let errors: { [key: string]: string } = {}
      const nameWithoutSpaces = values.name != null ? values.name.split(/ +/).join('') : ''
      errors = validateEmail(values.email, errors, i18t)
      errors = validatePassword(
        values.password,
        errors,
        RESET_PASSWORD_FIELDS.PASSWORD,
        i18t
      )
      errors = validatePassword(
        values.confirmNewPassword ?? '',
        errors,
        RESET_PASSWORD_FIELDS.CONFIRM_NEW_PASSWORD,
        i18t
      )
      if (
        errors.password == null &&
        errors.confirmNewPassword == null &&
        values.password !== values.confirmNewPassword
      ) {
        errors.confirmNewPassword = t`PasswordsNotMatch`
        errors.password = ''
      }
      errors = validateName(values.name, errors, nameWithoutSpaces, i18t)
      return errors
    },
    validateOnChange: false
  })

  return (
    <form
      onSubmit={form.handleSubmit}
      className={isMobile ? 'additional-fields-form' : ''}
    >
      <Box>
        <Typography className={`lato font-size ${isMobile ? 'center' : ''}`}>
          {t`SignUpFormAdditionalHeading`}
        </Typography>
        <Box className={`additional-fields ${isMobile ? 'mt-2' : ''}`}>
          <FormTextField
            id='name'
            name='name'
            value={form.values.name}
            label={t`Name`}
            errorField={form.errors.name}
            onChange={form.handleChange}
          />
          <FormTextField
            id='email'
            name='email'
            value={form.values.email}
            label={t`EmailSignIn`}
            errorField={form.errors.email}
            onChange={form.handleChange}
          />
          <FormTextField
            id='password'
            name='password'
            value={form.values.password}
            label={t`Password`}
            errorField={form.errors.password}
            onChange={form.handleChange}
            type={showPassword ? TEXT : PASSWORD}
            InputProps={{
              endAdornment: showPasswordToggleIcon(
                showPassword,
                setShowPassword
              )
            }}
          />
          <FormTextField
            id='confirmNewPassword'
            name='confirmNewPassword'
            value={form.values.confirmNewPassword as string}
            label={t`ConfirmPassword`}
            errorField={form.errors.confirmNewPassword}
            onChange={form.handleChange}
            type={showConfirmNewPassword ? TEXT : PASSWORD}
            InputProps={{
              endAdornment: showPasswordToggleIcon(
                showConfirmNewPassword,
                setShowConfirmNewPassword
              )
            }}
          />
          {isMobile && errorDisplay}
        </Box>
      </Box>
      <Box className={isMobile ? 'mobile-actions' : ''}>
        {isMobile && (
          <Box onClick={handleDiscard} className='discard-box'>
            <Typography>{t`Discard`}</Typography>
          </Box>
        )}
        <Button
          id='sign-up-btn'
          type='submit'
          className={`btn btn-responsive lato ${
            form.values.name === '' ? 'disabled' : ''
          }`}
          disabled={form.values.name === ''}
          style={{
            backgroundColor: customTheme.button,
            color: customTheme.buttonText
          }}
        >
          {t`SignUp`}
        </Button>
        {!isMobile && (
          <Box onClick={handleDiscard} className='discard-box'>
            <Typography>{t`Discard`}</Typography>
          </Box>
        )}
      </Box>
    </form>
  )
}

export default AdditionalInformationForm
