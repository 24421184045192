import React, { FC, ReactElement } from 'react'
import { Button, ButtonBase } from '@material-ui/core'
import TimerIcon from '@material-ui/icons/Timer'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'
import { useTracking } from 'react-tracking'
import NumberFormat from 'react-number-format'
import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'
import { RedeemCalc } from '../OfferListItem/OfferListItem'
import { OfferStatus } from '../../offerStatus'
import placeholderOffer from '../../../../assets/images/offer/placeholder-offer.png'
import { redeemOffer, getOfferList } from '../../actions'
import {
  getUserInfo,
  getUserOfferStatus,
  getEarningList
} from '../../../user/actions'
import { selectOfferStatus } from '../../../user/selectors'
import { diffFromNow } from '../../../core/utils/time'
import { IdList } from '../../../core/utils/id-list'
import { selectConfig } from '../../../config/selectors'
import {
  getCampaignList,
  getCampaignFeaturedList,
  getCampaignStatuses
} from '../../../campaign/actions'
import './OfferDrawerForFeatureFlag.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import { RedemptionCenterCondition } from '../../redemptionCenterCondition'
import MDEditor from '@uiw/react-md-editor'
import { CUSTOME_ERROR_MESSAGE } from '../../../constants'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

interface OfferDrawerForFeatureFlagProps {
  offerItem: Offer
  isOfferDetailsOpen: boolean
  setIsOfferDetailsOpen: (isOfferDetailsOpen: boolean) => void
  redeemCalc: RedeemCalc
  handleRedeemOffer: (offerRedemption: Redemption) => void
  showSpinner: () => void
  handleRedeemOfferError: () => void
  handleOpenTerms: () => void
}

const OfferDrawerForFeatureFlag: FC<OfferDrawerForFeatureFlagProps> = ({
  offerItem,
  isOfferDetailsOpen,
  setIsOfferDetailsOpen,
  redeemCalc,
  handleRedeemOffer,
  showSpinner,
  handleRedeemOfferError,
  handleOpenTerms
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const offerItemStatus: UserOfferStatus | undefined = useSelector(
    selectOfferStatus(offerItem.OfferID)
  )
  const config: ConfigState = useSelector(selectConfig)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const handleClickRedeemButton = async (event: any = null): Promise<any> => {
    if (event != null) {
      event.preventDefault()
    }
    showSpinner()

    return dispatch(redeemOffer(offerItem.OfferID))
      .then(async (response: Redemption) => {
        handleRedeemOffer(response)
        trackEvent({
          page: AnalyticsCategory.Redemption,
          action: 'Offer Redeemed',
          payload: {
            offerID: offerItem.OfferID
          }
        })
        return await Promise.all([
          dispatch(getUserInfo()),
          dispatch(getEarningList()),
          dispatch(getCampaignList()),
          dispatch(getCampaignFeaturedList()),
          dispatch(getCampaignStatuses()),
          dispatch(getUserOfferStatus()),
          dispatch(getOfferList())
        ])
      })
      .catch((error: Error) => {
        handleRedeemOfferError()
        toast.error(
          error?.message != null && error?.message.length > 0
            ? error?.message
            : `${CUSTOME_ERROR_MESSAGE} redeem offer for Feature Flag`
        )
        Sentry.captureException(error)
      })
  }
  const getImg = (): string =>
    offerItem.headerImageUrl != null
      ? offerItem.headerImageUrl
      : placeholderOffer

  const getPointsField = (): string => {
    if (config.WordsForPoints.short != null) {
      return t([config.WordsForPoints.short])
    }
    return t`pts`
  }

  const selectButtonType = (): ReactElement => {
    if (
      offerItemStatus !== undefined &&
      offerItemStatus.status === OfferStatus.unavailableLimit
    ) {
      return (
        <Button
          disabled
          classes={{ disabled: 'btn-disable' }}
          className='btn m-top-24'
        >
          {`${t`AvailableIn`} ${diffFromNow(offerItemStatus.NextResetAtUTC)}`}
        </Button>
      )
    } else {
      if (redeemCalc.isAvailable) {
        return (
          <Button
            id={IdList.redeemFor}
            onClick={handleClickRedeemButton}
            onTouchEnd={handleClickRedeemButton}
            className='btn m-top-24'
          >
            {offerItem.VeloAmount !== 0 ? (
              <>
                {t`RedeemFor`}
                &nbsp;
                <NumberFormat
                  value={offerItem.VeloAmount}
                  displayType='text'
                  thousandSeparator
                />
                &nbsp;
                {getPointsField()}
              </>
            ) : (
              t`RedeemForFree`
            )}
          </Button>
        )
      } else {
        return (
          <Button
            disabled
            classes={{ disabled: 'btn-disable' }}
            className='btn m-top-24 offer-drawer-new__btn__more'
          >
            {`${t('morePoints', { points: Math.abs(redeemCalc.difference) })}`}
          </Button>
        )
      }
    }
  }
  const isAvailablePointsBtn =
    offerItemStatus !== undefined &&
    offerItemStatus.status !== OfferStatus.unavailableLimit &&
    redeemCalc.isAvailable &&
    offerItem.VeloAmount !== 0
  return (
    <BaseDrawer
      isOpen={isOfferDetailsOpen}
      onClose={() => setIsOfferDetailsOpen(false)}
      customMargin={matchesMd ? '18px' : ''}
      dialogClassName={`${
        !matchesMd
          ? 'offer-detail-new-drawer'
          : 'offer-detail-new-drawer offer-detail-new-drawer__desktop'
      }`}
      isFromUpdatedUI={!matchesMd}
      isCentered={matchesMd}
      centerCondition={RedemptionCenterCondition.both}
      isFromUpdatedDesktopUI={matchesMd}
      noPadding={matchesMd}
    >
      <div className={`offer-drawer-new ${matchesMd ? 'h-50' : ''}`}>
        <div
          style={{ backgroundImage: `url(${getImg()})` }}
          className={`offer-drawer-new-img ${matchesMd ? 'img-updated' : ''}`}
        />
        <div
          className={`offer-drawer-new-details ${
            matchesMd ? 'details-updated' : ''
          }`}
        >
          <div className='offer-drawer-new__info'>
            <div className='offer-drawer-new__subtitle'>
              {offerItem.SubTitle}
            </div>
            <div className='offer-drawer-new__title'>{offerItem.Title}</div>
            {offerItem.LimitTimeFrameInDays !== 0 && (
              <span className='offer-drawer-new__timer'>
                <TimerIcon className='icon__ico offer-drawer-new__timer__ico' />
                <span className='offer-drawer-new__timer__txt'>
                  {`x${offerItem.RedemptionsAllowedInTimeFrame}`} {t`per`}{' '}
                  {offerItem.LimitTimeFrameInDays === 1
                    ? `${t`DAY`}`
                    : `${offerItem.LimitTimeFrameInDays} ${t`DAYS`}`}
                </span>
              </span>
            )}
            {isAvailablePointsBtn && (
              <div
                className={`offer-detail-points-btn  ${
                  !isAvailablePointsBtn ? 'locked-background' : ''
                }`}
              >
                <NumberFormat
                  value={offerItem.VeloAmount}
                  displayType='text'
                  thousandSeparator
                />
                &nbsp;{getPointsField()}
              </div>
            )}
            <div className='description'>
              <MDEditor.Markdown
                source={offerItem.Description}
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </div>
            {offerItem.TermsAndConditionsBody != null &&
              offerItem.TermsAndConditionsTitle != null &&
              (
                <ButtonBase
                  className='terms-link ptop-0'
                  onClick={handleOpenTerms}
                >
                  {offerItem.TermsAndConditionsTitle}
                </ButtonBase>
              )}
            {redeemCalc.isAvailable && (
              <div className='m-top-16 offer-drawer-new__new-balance'>
                {offerItem.VeloAmount !== 0 && (
                  <>
                    <span>{t`NewBalance`}</span>
                    <span>
                      <NumberFormat
                        value={redeemCalc.difference}
                        displayType='text'
                        thousandSeparator
                      />
                    </span>
                  </>
                )}
              </div>
            )}
            <span className='text-bottom-bumper' />
          </div>
        </div>
      </div>
      <div className={matchesMd ? 'btn-updated' : 'offer-drawer-new__btn'}>
        {selectButtonType()}
      </div>
    </BaseDrawer>
  )
}

export default OfferDrawerForFeatureFlag
