import React, { FC, useState, useEffect } from 'react'
import Joyride, {
  TooltipRenderProps,
  ACTIONS,
  EVENTS,
  STATUS
} from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentTutorialState } from './selectors'
import { selectUser } from '../user/selectors'
import {
  selectConfig,
  selectIsActionLoaded,
  selectIsSignupWelcomeEnabled,
  selectIsWelcomeMessage
} from '../config/selectors'
import {
  setCurrentStepTitle,
  setMoreTabOpenForTutorial,
  setTutorialOn
} from './actions'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import CloseIcon from '@material-ui/icons/Close'
import {
  GET_CAMPAIGN_CATEGORIES,
  GET_CAMPAIGN_FEATURED_LIST,
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_STATUSES
} from '../campaign/actionTypes'
import { useTracking } from 'react-tracking'
import { setHaveSeenTutorial } from '../user/actions'
import { userService } from '../user/service'
import { changeTab } from '../config/actions'
import { TabsName } from '../core/tabsName'
import './TutorialToolTipUpdated.scss'
import { ACTION_TYPES, removeScreenConstraint } from './TutorialToolTip'

const OVERLAY_COLOR = 'rgba(255, 255, 255, 0)'
const NO_SPOTLIGHT_COLOR = 'transparent'
const SPOTLIGHT_COLOR = '#BEBEBE'
const SCROLL_DURATION = 100
const Z_INDEX = 200

export function TUTORIAL_STEPS_UPDATED (): {
  TUTORIAL_STEP_TITLES: {
    [key: string]: string
  }
  TUTORIAL_STEP_DESCRIPTION: {
    [key: string]: string
  }
} {
  const config: ConfigState = useSelector(selectConfig)

  const TUTORIAL_STEP_TITLES = {
    EARN_TAB: config.TutorialSteps[0]?.Title ?? 'Earn',
    TRANSACTION_HISTORY: config.TutorialSteps[1]?.Title ?? 'Transaction History',
    REDEEM_TAB: config.TutorialSteps[2]?.Title ?? 'Redeem',
    MORE_TAB: config.TutorialSteps[3]?.Title ?? 'More'
  }

  const TUTORIAL_STEP_DESCRIPTION = {
    EARN_TAB:
      config.TutorialSteps[0]?.Description ??
      'Browse campaigns and learn ways to earn points.',
    TRANSACTION_HISTORY:
      config.TutorialSteps[1]?.Description ??
      'Keep track of points that you’ve earned from campaigns.',
    REDEEM_TAB:
      config.TutorialSteps[2]?.Description ??
      'View available offers that you can redeem your points on.',
    MORE_TAB:
      config.TutorialSteps[3]?.Description ??
      'Access additional information like language preferences, transaction history, promo codes and friend referrals.'
  }

  return {
    TUTORIAL_STEP_TITLES,
    TUTORIAL_STEP_DESCRIPTION
  }
}

const TutorialToolTipUpdated: FC = () => {
  const currentTutorialState = useSelector(selectCurrentTutorialState)
  const { featureFlags }: UserState = useSelector(selectUser)
  const turoriaList = TUTORIAL_STEPS_UPDATED()
  const { t } = useTranslation()
  const dispatch: Dispatch<any> = useDispatch()
  const isLoaded: boolean = useSelector(
    selectIsActionLoaded([
      GET_CAMPAIGN_LIST,
      GET_CAMPAIGN_FEATURED_LIST,
      GET_CAMPAIGN_CATEGORIES,
      GET_CAMPAIGN_STATUSES
    ])
  )
  const signupWelcomeEnabled = useSelector(selectIsSignupWelcomeEnabled)
  const isWelcome = useSelector(selectIsWelcomeMessage)
  const user: UserState = useSelector(selectUser)
  const { trackEvent } = useTracking()
  const [stepIndex, setStepIndex] = useState<any>(0)

  const steps = [
    {
      title: turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB,
      target: '.navigation-tabs__tab',
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.EARN_TAB,
      disableBeacon: true,
      offset: 0
    },
    {
      target: '.balance-mobile-icon',
      title: turoriaList.TUTORIAL_STEP_TITLES.TRANSACTION_HISTORY,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.TRANSACTION_HISTORY,
      disableBeacon: true,
      placement: 'bottom-end' as 'bottom-end',
      offset: 0
    },
    {
      target: '.navigation-tabs__tab:nth-of-type(2)',
      title: turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.REDEEM_TAB,
      disableBeacon: true,
      placement: 'bottom-start' as 'bottom-start',
      offset: 0
    },
    {
      target: '.navigation-tabs__tab:nth-of-type(3)',
      title: turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.MORE_TAB,
      disableBeacon: true,
      placement: 'bottom-end' as 'bottom-end',
      offset: 0,
      isFixed: true
    }
  ]

  const INDEX_MATCH = {
    [`${turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB}`]: 1,
    [`${turoriaList.TUTORIAL_STEP_TITLES.TRANSACTION_HISTORY}`]: 2,
    [`${turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB}`]: 3,
    [`${turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB}`]: 4
  }

  const NO_SPOTLIGHT_STEP_TITLE = [
    turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB,
    turoriaList.TUTORIAL_STEP_TITLES.TRANSACTION_HISTORY,
    turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB
  ]

  const Tooltip: React.ElementType<TooltipRenderProps> = ({
    step,
    backProps,
    primaryProps,
    isLastStep,
    tooltipProps
  }) => (
    <>
      <div
        {...tooltipProps}
        className={`${
          step.placement === 'bottom-start'
            ? 'tooltip-container-step-three'
            : step.placement === 'bottom-end'
            ? 'tooltip-container-step-other'
            : 'tooltip-container-step-one'
        } ${
          step.title === turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB
            ? 'tooltip-container-step-four'
            : ''
        }`}
        role='tooltip'
      >
        <div className='steps-wrapper'>
          <div className='title-block'>
            <h4 className='title'>{step.title}</h4>
            <div
              onClick={() => dispatch(setTutorialOn(false))}
              role='button'
              aria-label='Close'
            >
              <CloseIcon className='close-updated' />
            </div>
          </div>
          <div className='content'>{step.content}</div>
          <div className='bottom-block-updated'>
            {step.title !== turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB ? (
              <div
                {...backProps}
                className='previous-button'
                role='button'
                aria-label='Previous'
              >
                {t`Previous`}
              </div>
            ) : (
              <div className='placeholder-button' />
            )}

            <div className='progress-count'>
              {`${INDEX_MATCH[`${step.title as string}`]} ${t`Of`} ${
                steps.length
              }`}
            </div>

            <div
              className='next-button'
              {...primaryProps}
              role='button'
              aria-label={isLastStep ? 'Finish' : 'Next'}
            >
              {isLastStep ? t`Finish` : t`Next`}
            </div>
          </div>
        </div>
      </div>
    </>
  )

  useEffect(() => {
    if (
      !isWelcome &&
      !signupWelcomeEnabled &&
      isLoaded &&
      user.HaveSeenTutorial === false
    ) {
      dispatch(setTutorialOn(true))
      trackEvent({
        action: 'Start Tutorial',
        category: 'Tutorial',
        payload: {
          Origin: 'First Time'
        }
      })
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const setTutoSeenFunc = async () => {
        dispatch(setHaveSeenTutorial(true))
        await userService.setTutorialSeen()
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setTutoSeenFunc()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, signupWelcomeEnabled, isWelcome, user])

  const constrainScreen = (): any => {
    document.body.style.overflow = 'hidden'
    document.body.style.touchAction = 'none'
    document.body.style.height = '100%'
  }

  const handleJoyrideCallback = (data: any): void => {
    const { action, index, status, type } = data
    if (type === EVENTS.TOUR_START) {
      constrainScreen()
    }
    if (type === EVENTS.STEP_BEFORE) {
      dispatch(setCurrentStepTitle(data.step.title))
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB) {
        dispatch(changeTab(TabsName.More))
        dispatch(setMoreTabOpenForTutorial(true))
      }
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB) {
        dispatch(changeTab(TabsName.Redeem))
      }
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB) {
        if (data.action === ACTION_TYPES.PREV) {
          dispatch(changeTab(TabsName.Earn))
        }
      }
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB) {
        if (
          data.action === ACTION_TYPES.NEXT ||
          data.action === ACTION_TYPES.CLOSE
        ) {
          dispatch(setMoreTabOpenForTutorial(false))
          dispatch(changeTab(TabsName.Earn))
        }
        if (data.action === ACTION_TYPES.PREV) {
          dispatch(setMoreTabOpenForTutorial(false))
          dispatch(changeTab(TabsName.Redeem))
        }
      }
      setStepIndex(parseInt(index) + (action === ACTIONS.PREV ? -1 : 1))
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setStepIndex(0)
      dispatch(setTutorialOn(false))
      dispatch(setMoreTabOpenForTutorial(false))
      removeScreenConstraint()
      trackEvent({
        action: 'Complete Tutorial',
        category: 'Tutorial'
      })
    }

    if (type === EVENTS.TOUR_STATUS) {
      if (data.action === ACTION_TYPES.START) {
        constrainScreen()
      }
      if (data.action === ACTION_TYPES.STOP) {
        if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB) {
          dispatch(changeTab(TabsName.Earn))
        }
        dispatch(setCurrentStepTitle(''))
        setStepIndex(0)
        dispatch(setTutorialOn(false))
        dispatch(setMoreTabOpenForTutorial(false))
        removeScreenConstraint()
        trackEvent({
          action: 'Close Tutorial',
          category: 'Tutorial',
          payload: {
            Step: parseInt(stepIndex) + 1
          }
        })
      }
    }
  }

  return (
    <Joyride
      scrollDuration={SCROLL_DURATION}
      disableScrollParentFix
      steps={steps}
      run={currentTutorialState.tutorialOn && featureFlags.UX_UPDATES}
      tooltipComponent={Tooltip}
      disableOverlayClose={false}
      continuous
      showProgress
      floaterProps={{
        disableAnimation: true,
        hideArrow: true
      }}
      styles={{
        options: {
          zIndex: Z_INDEX,
          width: '100%',
          overlayColor: OVERLAY_COLOR
        },
        spotlight: {
          borderRadius: 8,
          backgroundColor: `${
            NO_SPOTLIGHT_STEP_TITLE.includes(
              currentTutorialState.currentStepTitle
            )
              ? NO_SPOTLIGHT_COLOR
              : SPOTLIGHT_COLOR
          }`,
          height: '3.5em'
        }
      }}
      spotlightPadding={0}
      callback={handleJoyrideCallback}
      stepIndex={stepIndex}
    />
  )
}

export default TutorialToolTipUpdated
