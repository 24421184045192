import React, { useEffect, useCallback, useState } from 'react'
import { CUSTOME_ERROR_MESSAGE } from './constants'
import { Dispatch } from 'redux'
import { AnalyticsProvider } from 'use-analytics'
import { Router, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { StylesProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'
import { toast, ToastContainer } from 'react-toastify'
import Paper from '@material-ui/core/Paper'
import { analytics } from './core/analytics/analytics'
import Interceptor from './core/api/Interceptor'
import Header from './ui/Header/Header'
import SignIn from './auth/SignIn/SignIn'
import SignUp from './auth/SignUp/SignUp'
import Home from './home/Home'
import TermsConditions from './ui/TermsConditions/TermsConditions'
import ErrorPage from './ui/Error/ErrorPage'
import Error from './ui/Error/Error'
import { RoutePath } from './core/routes/route-path'
import { selectUser } from './user/selectors'
import TrackAnalytics from './core/analytics/TrackAnaltytics'
import {
  getConfigInfo,
  setAppStartUTC,
  setAppError,
  getConfigInfoByTenant
} from './config/actions'
import { getReferralInfo } from './campaign/actions'
import history from './core/history'
import ProtectedRoute from './core/routes/ProtectedRoute'
import ConnectAccount from './provider/ConnectAccount/ConnectAccount'
import InviteCode from './campaign/InviteCode/InviteCode'
import ReferFriend from './campaign/ReferFriend/ReferFriend'
import OptedOut from './user/OptedOut/OptedOut'
import Suspended from './user/Suspended/Suspended'
import PullToRefresh from 'react-simple-pull-to-refresh'
import Spinner from './ui/animation/Spinner/Spinner'
import DevLabel from './ui/devLabel/DevLabel'
import { setHomePath, setSignIn, signIn } from './auth/actions'
import StandaloneRoute from './core/routes/StandaloneRoute'
import TermsRoute from './core/routes/TermsRoute'
import ConnectErrorBox from './ui/ConnectError/ConnectErrorBox/ConnectErrorBox'
import LockedByConnectErr from './ui/ConnectError/LockedByConnectErr/LockedByConnectErr'
import { AppErrorType } from './config/appErrorType'
import { isServerDown } from './core/api/health'
import { selectConfig } from './config/selectors'
import { getInitialTenantInfo } from './core/api/init'
import initTraslation from '../locales/i18n'
import ManageAccount from './auth/ManageAccount/ManageAccount'
import PasswordReset from './auth/PasswordReset/PasswordReset'
import { setIsRefreshAnimationCounter } from './user/actions'
import { selectAuth } from './auth/selectors'
import UpdatedCampaigns from './campaign/UpdatedCampaigns/UpdatedCampaigns'
import RedeemUpdated from './offer/RedeemUpdated/RedeemUpdated'
import _ from 'lodash'
import AddToHomescreenInstructions from './ui/AddToHomescreen/AddToHomescreenInstructions'
import useTheme from './core/utils/useTheme'

const SPINNER_DIMENSION = 60
const isLoginKey = 'isAuth'

const {
  REACT_APP_SENTRY_URL,
  REACT_APP_VERSION = '',
  REACT_APP_ENVIRONMENT,
  REACT_APP_TENANT_ID_FOR_CLIENT
} = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_URL,
  environment: REACT_APP_ENVIRONMENT,
  release: REACT_APP_VERSION
})

initTraslation().then(
  () => {
  },
  () => {
  }
)
const App: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const user: UserState = useSelector(selectUser)
  const config: ConfigState = useSelector(selectConfig)
  const [isHideHeaderAndCustomWrapper, setIsHideHeaderAndCustomWrapper] = useState<boolean>(false)
  const auth: AuthState = useSelector(selectAuth)
  useTheme(config.CssTheme)

  const getData = async (): Promise<any> => {
    dispatch(setAppStartUTC())
      .catch((error: Error) => Sentry.captureException(error))
    let tenantInfo: {[key: string]: boolean} = {}
    const initFuncList = []
    const isLoginKeyEmpty = _.isEmpty(localStorage.getItem(isLoginKey))
    if (REACT_APP_TENANT_ID_FOR_CLIENT != null && REACT_APP_TENANT_ID_FOR_CLIENT !== '') {
      initFuncList.push(dispatch(getConfigInfoByTenant(REACT_APP_TENANT_ID_FOR_CLIENT)))
      tenantInfo = await getInitialTenantInfo(REACT_APP_TENANT_ID_FOR_CLIENT)
    } else {
      initFuncList.push(dispatch(getConfigInfo()))
      tenantInfo = await getInitialTenantInfo()
    }

    if (tenantInfo.IsStandalonModeSupported && isLoginKeyEmpty) {
      initFuncList.push(dispatch(setSignIn(false)))
    } else {
      initFuncList.push(dispatch(signIn()))
      initFuncList.push(dispatch(setHomePath(true)))
    }

    if (
      (!tenantInfo.IsStandalonModeSupported && tenantInfo.IsReferralAvailable) ||
      (tenantInfo.IsStandalonModeSupported &&
        !isLoginKeyEmpty &&
        tenantInfo.IsReferralAvailable)
    ) {
      initFuncList.push(
        dispatch(getReferralInfo(REACT_APP_TENANT_ID_FOR_CLIENT))
      )
    }

    return await Promise.all(initFuncList)
      .catch((error: Error) => {
        toast.error(
          error?.message != null && error?.message.length > 0
            ? error?.message
            : `${CUSTOME_ERROR_MESSAGE} initiate app`
        )
        Sentry.captureException(error)
      })
  }

  const checkServer = useCallback(() => {
    isServerDown().then((down) => {
      if (down) {
        dispatch(setAppError(AppErrorType.maintenance))
        history.push(RoutePath.ErrorPage)
      } else {
        if (config.AppErrorType === AppErrorType.maintenance) {
          dispatch(setAppError(AppErrorType.none))
        }
      }
    }).catch((error: Error) => {
      Sentry.captureException(error)
    })
  }, [config, dispatch])

  const checkAndGetData = async (): Promise<any> => {
    dispatch(setIsRefreshAnimationCounter(!user.isRefreshCounter))
    dispatch(setAppError(AppErrorType.none))
    checkServer()
    return await getData()
  }

  useEffect((): void => {
    checkAndGetData().then(
      () => {},
      () => {}
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let userId = 'guest'

    if (user.UserID !== undefined) {
      userId = user.UserID
    }

    analytics.identify(userId).then(
      () => {
      },
      () => {
      }
    )
  }, [user])

  useEffect(() => {
    setIsHideHeaderAndCustomWrapper(
      config.IsStandalonModeSupported && !auth.isAuth
    )
  }, [auth, config])

  return (
    <StylesProvider injectFirst>
      <AnalyticsProvider instance={analytics}>
        <TrackAnalytics>
          <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
            <Router history={history}>
              <Interceptor>
                <LockedByConnectErr>
                  {!isHideHeaderAndCustomWrapper && auth.isHomePath && <Header />}
                </LockedByConnectErr>

                <ConnectErrorBox handleRetry={checkAndGetData} />

                <LockedByConnectErr>
                  <PullToRefresh onRefresh={checkAndGetData} refreshingContent={<Spinner height={SPINNER_DIMENSION} width={SPINNER_DIMENSION} />}>
                    <Paper className={`${!isHideHeaderAndCustomWrapper && auth.isHomePath === true ? 'content-wrapper' : ''} `}>
                      <Switch>
                        <ProtectedRoute exact path={RoutePath.ManageAccount} component={ManageAccount} />
                        <ProtectedRoute exact path={RoutePath.Home} component={Home} />
                        <ProtectedRoute exact path={RoutePath.Redemption} component={Home} />
                        <ProtectedRoute exact path={RoutePath.Tutorial} component={Home} />
                        <ProtectedRoute exact path={RoutePath.PointHistory} component={Home} />
                        <StandaloneRoute exact path={RoutePath.SignIn} component={SignIn} />
                        <StandaloneRoute exact path={RoutePath.PasswordReset} component={PasswordReset} />
                        <StandaloneRoute exact path={RoutePath.SignUp} component={SignUp} />
                        <TermsRoute exact path={RoutePath.Terms} component={TermsConditions} />
                        <ProtectedRoute exact path={RoutePath.ConnectAccount} component={ConnectAccount} />
                        <ProtectedRoute exact path={RoutePath.Invite} component={InviteCode} />
                        <ProtectedRoute exact path={RoutePath.EarnUpdated} component={UpdatedCampaigns} />
                        <ProtectedRoute exact path={RoutePath.RedeemUpdated} component={RedeemUpdated} />
                        <ProtectedRoute
                          exact
                          path={RoutePath.AddToHomescreenInstructions}
                          component={AddToHomescreenInstructions}
                        />
                        {config.IsReferralAvailable && (
                          <ProtectedRoute
                            exact
                            path={RoutePath.Refer}
                            component={ReferFriend}
                          />
                        )}
                        <ProtectedRoute
                          exact
                          path={RoutePath.OptedOut}
                          component={OptedOut}
                        />
                        <ProtectedRoute
                          exact
                          path={RoutePath.Suspended}
                          component={Suspended}
                        />
                        <ProtectedRoute path='*' component={Error} />
                      </Switch>

                      <ToastContainer />
                    </Paper>
                  </PullToRefresh>
                </LockedByConnectErr>

                <DevLabel />
              </Interceptor>
            </Router>
          </Sentry.ErrorBoundary>
        </TrackAnalytics>
      </AnalyticsProvider>
    </StylesProvider>
  )
}

export default App
