import React, { ReactElement } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import '../SignUp.scss'
import './AdditionalInformationForm.scss'
import { Link } from 'react-router-dom'
import AdditionalInformationForm from './AdditionalInformationForm'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { RoutePath } from '../../../core/routes/route-path'
import './AdditionalInformationFormMobileView.scss'

interface AdditionalInformationFormMobileViewProps {
  customTheme: { [key: string]: string }
  handleAdditionalInfoSubmit: (info: SignUpForm) => void
  handleDiscard: () => void
  errorDisplay: ReactElement | null
}

const AdditionalInformationFormMobileView = ({
  customTheme,
  handleAdditionalInfoSubmit,
  handleDiscard,
  errorDisplay
}: AdditionalInformationFormMobileViewProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Box className='additional-form-box-mob'>
      <Link to={RoutePath.SignIn} className='back-to-sign-in'>
        <ArrowBackIcon fontSize='default' className='arrow-responsive' />
      </Link>
      <Typography className='sign-up-heading'>{t`SignUp!`}</Typography>
      <AdditionalInformationForm
        customTheme={customTheme}
        handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
        handleDiscard={handleDiscard}
        isMobile
        errorDisplay={errorDisplay}
      />
    </Box>
  )
}

export default AdditionalInformationFormMobileView
