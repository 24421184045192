/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
import {
  GET_CAMPAIGN_CATEGORIES,
  GET_CAMPAIGN_FEATURED_LIST,
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_STATUSES
} from '../campaign/actionTypes'
import { useSelector, useDispatch } from 'react-redux'
import Joyride, {
  TooltipRenderProps,
  ACTIONS,
  EVENTS,
  STATUS
} from 'react-joyride'
import React, { FC, useState, useEffect } from 'react'
import { Dispatch } from 'redux'
import {
  setCurrentStepTitle,
  setDrawerOpenForTutorial,
  setMoreTabOpenForTutorial,
  setTutorialOn
} from './actions'
import { changeTab } from '../config/actions'
import { TabsName } from '../core/tabsName'
import { selectCurrentTutorialState } from './selectors'
import CloseIcon from '@material-ui/icons/Close'
import { selectUser } from '../user/selectors'
import { userService } from '../user/service'
import { setHaveSeenTutorial } from '../user/actions'
import {
  selectIsSignupWelcomeEnabled,
  selectIsActionLoaded,
  selectIsWelcomeMessage,
  selectConfig
} from '../config/selectors'
import coinIcon from '../../assets/images/coin.png'
import clockIcon from '../../assets/images/clock-outline.png'
import map from '../../assets/images/map-marker-outline.png'
import timer from '../../assets/images/timer-outline.png'

import './TutorialToolTip.scss'
import { useTracking } from 'react-tracking'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../core/utils/css-selectors'

export const removeScreenConstraint = (): any => {
  document.body.style.removeProperty('overflow')
  document.body.style.removeProperty('touch-action')
  document.body.style.removeProperty('height')
  setTimeout(() => {
    document.body.style.removeProperty('overflow')
    document.body.style.removeProperty('touch-action')
    document.body.style.removeProperty('height')
  }, DEFAULT_TIMEOUT)
}

export function TUTORIAL_STEPS (): any {
  const config: ConfigState = useSelector(selectConfig)

  const TUTORIAL_STEP_TITLES = {
    EARN_TAB: config.TutorialSteps[0]?.Title ?? 'Earn Tab',
    FEATURE_MONTHLY_CAMPAIGN:
      config.TutorialSteps[1]?.Title ?? 'Featured Monthly Campaign',
    CAMPAIGNS: config.TutorialSteps[2]?.Title ?? 'Campaigns',
    CAMPAIGN_DETAIL: config.TutorialSteps[3]?.Title ?? 'Campaign Details',
    REDEEM_TAB: config.TutorialSteps[4]?.Title ?? 'Redeem Tab',
    REDEEM_POINT: config.TutorialSteps[5]?.Title ?? 'Redeem Points',
    MORE_TAB: config.TutorialSteps[6]?.Title ?? 'More Tab'
  }

  const TUTORIAL_STEP_DESCRIPTION = {
    EARN_TAB:
      config.TutorialSteps[0]?.Description ??
      'Participate in any of the campaigns listed on this page to earn points.',
    FEATURE_MONTHLY_CAMPAIGN:
      config.TutorialSteps[1]?.Description ??
      'Check out featured campaigns to discover new ways to earn points.',
    CAMPAIGNS:
      config.TutorialSteps[2]?.Description ??
      'Slide to the right to view other campaigns. Tap on any of the cards to view the actions required to earn points for that campaign.',
    CAMPAIGN_DETAIL:
      config.TutorialSteps[3]?.Description ??
      'Each campaign has it`s own unique set of features. These include:',
    REDEEM_TAB:
      config.TutorialSteps[4]?.Description ??
      'This is where you will find all the currently available redemption offers on Transit GO Rewards.',
    REDEEM_POINT:
      config.TutorialSteps[5]?.Description ??
      'Tap on any of the current offers to view it`s details, or use the available points in your wallet to redeem that offer.',
    MORE_TAB:
      config.TutorialSteps[6]?.Description ??
      'Open the More tab to view your transaction history, or provide your promo code for special campaigns or offers.'
  }

  return {
    TUTORIAL_STEP_TITLES,
    TUTORIAL_STEP_DESCRIPTION
  }
}

export const ACTION_TYPES = {
  START: 'start',
  STOP: 'stop',
  NEXT: 'next',
  CLOSE: 'close',
  PREV: 'prev'
}

const DEFAULT_TIMEOUT = 500

const TutorialToolTip: FC = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch<any> = useDispatch()
  const isLoaded: boolean = useSelector(
    selectIsActionLoaded([
      GET_CAMPAIGN_LIST,
      GET_CAMPAIGN_FEATURED_LIST,
      GET_CAMPAIGN_CATEGORIES,
      GET_CAMPAIGN_STATUSES
    ])
  )
  const [stepIndex, setStepIndex] = useState<any>(0)
  const currentTutorialState = useSelector(selectCurrentTutorialState)
  const user: UserState = useSelector(selectUser)
  const signupWelcomeEnabled = useSelector(selectIsSignupWelcomeEnabled)
  const isWelcome = useSelector(selectIsWelcomeMessage)
  const { trackEvent } = useTracking()
  const turoriaList = TUTORIAL_STEPS()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { featureFlags }: UserState = useSelector(selectUser)

  const steps = [
    {
      title: turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB,
      target: '.navigation-tabs__tab',
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.EARN_TAB,
      disableBeacon: true,
      placement: 'bottom' as 'bottom',
      offset: 0
    },
    {
      target: '.featured__preview',
      title: turoriaList.TUTORIAL_STEP_TITLES.FEATURE_MONTHLY_CAMPAIGN,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.FEATURE_MONTHLY_CAMPAIGN,
      disableBeacon: true,
      placement: 'bottom' as 'bottom',
      offset: 15
    },
    {
      target: '.campaign__list__item',
      title: turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGNS,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.CAMPAIGNS,
      disableBeacon: true,
      placement: 'top' as 'top'
    },
    {
      target: '.campaign-drawer__details',
      title: turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGN_DETAIL,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.CAMPAIGN_DETAIL,
      disableBeacon: true,
      isFixed: true,
      placement: 'top' as 'top'
    },
    {
      target: '.navigation-tabs__tab:nth-of-type(2)',
      title: turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.REDEEM_TAB,
      disableBeacon: true,
      isFixed: true,
      placement: 'bottom-start' as 'bottom-start',
      offset: 0
    },
    {
      target: `${
        matchesMd || (!matchesMd && !featureFlags.UX_UPDATES)
          ? '.offer__list__item:nth-of-type(1)'
          : '.offer-list-item-updated'
      }`,
      title: turoriaList.TUTORIAL_STEP_TITLES.REDEEM_POINT,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.REDEEM_POINT,
      disableBeacon: true,
      isFixed: true,
      placement: 'bottom' as 'bottom',
      offset: 15
    },
    {
      target: '.navigation-tabs__tab:nth-of-type(3)',
      title: turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB,
      content: turoriaList.TUTORIAL_STEP_DESCRIPTION.MORE_TAB,
      disableBeacon: true,
      isFixed: true,
      placement: 'bottom-end' as 'bottom-end',
      offset: 0
    }
  ]
  const REDUCED_SPOTLIGHT_STEP_TITLE = [
    turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB,
    turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB,
    turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB
  ]

  const INDEX_MATCH = {
    [`${turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB}`]: 1,
    [`${turoriaList.TUTORIAL_STEP_TITLES.FEATURE_MONTHLY_CAMPAIGN}`]: 2,
    [`${turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGNS}`]: 3,
    [`${turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGN_DETAIL}`]: 4,
    [`${turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB}`]: 5,
    [`${turoriaList.TUTORIAL_STEP_TITLES.REDEEM_POINT}`]: 6,
    [`${turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB}`]: 7
  }
  const Tooltip: React.ElementType<TooltipRenderProps> = ({
    step,
    backProps,
    primaryProps,
    isLastStep,
    tooltipProps
  }) => (
    <>
      <div
        {...tooltipProps}
        className={
          step.placement === 'bottom-start'
            ? 'tooltip_container_top_start'
            : step.placement === 'bottom-end'
            ? 'tooltip_container_top_end'
            : step.placement === 'top'
            ? 'tooltip_container_bottom'
            : 'tooltip_container_top'
        }
      >
        <div className='title-block'>
          <h3 className='title'>{step.title}</h3>
          <div onClick={() => dispatch(setTutorialOn(false))}>
          <button aria-label='Close' className='close-button'>
            <CloseIcon htmlColor='#191919' style={{ width: 22, height: 22 }} />
          </button>
          </div>
        </div>
        {step.title === turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGN_DETAIL ? (
          <div className='mt-11'>
            <p className='upper-word'>
              {
                'Each campaign has it`s own unique set of features. These include: '
              }
            </p>
            <div className='icon-bundle'>
              <img
                src={coinIcon}
                style={{
                  marginLeft: '0.1em',
                  width: '4vw',
                  marginRight: '0.3em'
                }}
                alt='Coin Icon'
              />
              <p>The number of points you will earn</p>
            </div>
            <div className='icon-bundle'>
              <img
                src={map}
                style={{
                  marginLeft: '0.1em',
                  width: '4vw',
                  marginRight: '0.3em'
                }}
                alt='Map Icon'
              />
              <p>Location at which an action is required</p>
            </div>
            <div className='icon-bundle'>
              <img
                src={clockIcon}
                style={{
                  marginLeft: '0.1em',
                  width: '4vw',
                  marginRight: '0.3em'
                }}
                alt='Clock Icon'
              />
              <p>Applicable time period</p>
            </div>
            <div className='icon-bundle'>
              <img
                src={timer}
                style={{
                  marginLeft: '0.1em',
                  width: '4vw',
                  marginRight: '0.3em'
                }}
                alt='Timer Icon'
              />
              <p>Maximum uses per day</p>
            </div>
          </div>
        ) : (
          <div className='mt-10'>{step.content}</div>
        )}
        <div className='bottom-block'>
          {step.title !== turoriaList.TUTORIAL_STEP_TITLES.EARN_TAB ? (
            <div {...backProps} className='mui-previous-tour-button'>
              {t`Previous`}
            </div>
          ) : (
            <div className='hideBackButton' />
          )}
          <div className='progressCount'>
            {`${INDEX_MATCH[`${step.title}`]} ${t`Of`} ${steps.length}`}
          </div>
          <div className='nextButton' {...primaryProps}>
            {isLastStep ? t`Finish` : t`Next`}
          </div>
        </div>
      </div>
    </>
  )
  useEffect(() => {
    if (
      !isWelcome &&
      !signupWelcomeEnabled &&
      isLoaded &&
      user.HaveSeenTutorial === false
    ) {
      dispatch(setTutorialOn(true))
      trackEvent({
        action: 'Start Tutorial',
        category: 'Tutorial',
        payload: {
          Origin: 'First Time'
        }
      })
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const setTutoSeenFunc = async () => {
        dispatch(setHaveSeenTutorial(true))
        await userService.setTutorialSeen()
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setTutoSeenFunc()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, signupWelcomeEnabled, isWelcome, user])

  const constrainScreen = (): any => {
    document.body.style.overflow = 'hidden'
    document.body.style.touchAction = 'none'
    document.body.style.height = '100%'
  }

  const handleJoyrideCallback = (data: any): void => {
    const { action, index, status, type } = data
    if (type === EVENTS.TOUR_START) {
      constrainScreen()
    }
    if (type === EVENTS.STEP_BEFORE) {
      dispatch(setCurrentStepTitle(data.step.title))
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB) {
        dispatch(changeTab(TabsName.More))
      }
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGNS) {
        constrainScreen()
      }
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGNS) {
        if (
          data.action === ACTION_TYPES.NEXT ||
          data.action === ACTION_TYPES.CLOSE
        ) {
          dispatch(setDrawerOpenForTutorial(true))
        }
      }
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.REDEEM_TAB) {
        if (data.action === ACTION_TYPES.PREV) {
          dispatch(changeTab(TabsName.Earn))
        }
      }
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.REDEEM_POINT) {
        if (
          data.action === ACTION_TYPES.NEXT ||
          data.action === ACTION_TYPES.CLOSE
        ) {
          dispatch(setMoreTabOpenForTutorial(true))
        }
      }
      if (
        data.step.title === turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGN_DETAIL
      ) {
        if (data.action === ACTION_TYPES.NEXT) {
          dispatch(changeTab(TabsName.Redeem))
          dispatch(
            setCurrentStepTitle(turoriaList.TUTORIAL_STEP_TITLES.REDEEM_POINT)
          )
        }
      }
      if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB) {
        if (
          data.action === ACTION_TYPES.NEXT ||
          data.action === ACTION_TYPES.CLOSE
        ) {
          dispatch(changeTab(TabsName.Earn))
        }
        if (data.action === ACTION_TYPES.PREV) {
          dispatch(setMoreTabOpenForTutorial(false))
          dispatch(changeTab(TabsName.Redeem))
          dispatch(
            setCurrentStepTitle(turoriaList.TUTORIAL_STEP_TITLES.REDEEM_POINT)
          )
        }
      }
      setStepIndex(parseInt(index) + (action === ACTIONS.PREV ? -1 : 1))
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setStepIndex(0)
      dispatch(setTutorialOn(false))
      dispatch(setMoreTabOpenForTutorial(false))
      dispatch(setDrawerOpenForTutorial(false))
      removeScreenConstraint()
      trackEvent({
        action: 'Complete Tutorial',
        category: 'Tutorial'
      })
    }

    if (type === EVENTS.TOUR_STATUS) {
      if (data.action === ACTION_TYPES.START) {
        constrainScreen()
      }
      if (data.action === ACTION_TYPES.STOP) {
        if (data.step.title === turoriaList.TUTORIAL_STEP_TITLES.MORE_TAB) {
          dispatch(changeTab(TabsName.Earn))
        }

        if (
          data.step.title === turoriaList.TUTORIAL_STEP_TITLES.CAMPAIGN_DETAIL
        ) {
          dispatch(setDrawerOpenForTutorial(false))
        }
        dispatch(setCurrentStepTitle(''))
        setStepIndex(0)
        dispatch(setTutorialOn(false))
        dispatch(setMoreTabOpenForTutorial(false))
        dispatch(setDrawerOpenForTutorial(false))
        removeScreenConstraint()
        trackEvent({
          action: 'Close Tutorial',
          category: 'Tutorial',
          payload: {
            Step: parseInt(stepIndex) + 1
          }
        })
      }
    }
  }
  return (
    <>
      <Joyride
        scrollDuration={100}
        disableScrollParentFix
        stepIndex={stepIndex}
        disableOverlayClose={false}
        run={currentTutorialState.tutorialOn && !featureFlags.UX_UPDATES}
        steps={steps}
        continuous
        showProgress
        tooltipComponent={Tooltip}
        floaterProps={{
          disableAnimation: true,
          hideArrow: true
        }}
        styles={{
          options: {
            zIndex: 100000,
            width: '100%'
          },
          spotlight: {
            borderRadius: 8
          }
        }}
        callback={handleJoyrideCallback}
        spotlightPadding={
          REDUCED_SPOTLIGHT_STEP_TITLE.includes(
            currentTutorialState.currentStepTitle
          )
            ? 0
            : 10
        }
      />
    </>
  )
}

export default TutorialToolTip
