import { Dispatch } from 'redux'

import * as actionTypes from './actionTypes'
import { campaignService } from './service'
import { setLoaded } from '../config/actions'

export const getCampaign: any = (id: string) => async (dispatch: Dispatch<CampaignAction>): Promise<CampaignAction> => {
  return await campaignService.getCampaign(id)
    .then((data) => dispatch(setCampaign(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CAMPAIGN)))
}

export const getCampaignHiddenList: any = (campaignIDList: string[]) => async (dispatch: Dispatch<CampaignListAction | LoadedAction>): Promise<CampaignListAction> => {
  return await Promise.all(campaignIDList.map(async campaignID => (await campaignService.getCampaign(campaignID))))
    .then((data: any) => dispatch(setCampaignHiddenList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CAMPAIGN_HIDDEN_LIST)))
}

export const getCampaignList: any = () => async (dispatch: Dispatch<CampaignListAction | LoadedAction>): Promise<CampaignListAction> => {
  return await campaignService.getCampaignList()
    .then((data) => dispatch(setCampaignList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CAMPAIGN_LIST)))
}

export const clearCampaignList: any = () => async (dispatch: Dispatch<CampaignListAction>): Promise<CampaignListAction> => {
  return await Promise.resolve(dispatch(setClearCampaignList()))
}

export const getCampaignFeaturedList: any = () => async (dispatch: Dispatch<CampaignFeaturedListAction | LoadedAction>): Promise<CampaignFeaturedListAction> => {
  return await campaignService.getCampaignFeaturedList()
    .then((data) => dispatch(setCampaignFeaturedList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CAMPAIGN_FEATURED_LIST)))
}

export const getCampaignCategories: any = () => async (dispatch: Dispatch<CampaignCategoriesListAction | LoadedAction>): Promise<CampaignCategoriesListAction> => {
  return await campaignService.getCampaignCategories()
    .then((data) => dispatch(setCampaignCategories(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CAMPAIGN_CATEGORIES)))
}

export const getCampaignStatuses: any = () => async (dispatch: Dispatch<CampaignStatusListAction | LoadedAction>): Promise<CampaignStatusListAction> => {
  return await campaignService.getCampaignStatuses()
    .then((data) => dispatch(setCampaignStatuses(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CAMPAIGN_STATUSES)))
}

export const participatePromotion: any = (promotion: ParticipatePromotion) => async (dispatch: Dispatch<ParticipatePromotionAction | LoadedAction>): Promise<ParticipatePromotionAction> => {
  return await campaignService.participatePromotion(promotion)
    .then((data) => dispatch(setParticipatePromotion(data)))
    .finally(() => dispatch(setLoaded(actionTypes.PARTICIPATE_PROMOTION)))
}
export const getReferralInfo: any = (TenantID: string) => async (dispatch: Dispatch<CampaignReferralAction | LoadedAction>): Promise<CampaignReferralAction> => {
  return await campaignService.getReferralInfo(TenantID)
    .then((data) => dispatch(setReferralInfo(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_REFERRAL)))
}

const setCampaign = (payload: Campaign): CampaignAction => ({
  type: actionTypes.GET_CAMPAIGN,
  payload
})

const setCampaignHiddenList = (payload: Campaign[]): CampaignAction => ({
  type: actionTypes.GET_CAMPAIGN_HIDDEN_LIST,
  payload
})

const setCampaignList = (payload: Campaign[]): CampaignListAction => ({
  type: actionTypes.GET_CAMPAIGN_LIST,
  payload
})

const setClearCampaignList = (): CampaignListAction => ({
  type: actionTypes.CLEAR_CAMPAIGN_LIST,
  payload: []
})

const setCampaignFeaturedList = (payload: CampaignFeatured[]): CampaignFeaturedListAction => ({
  type: actionTypes.GET_CAMPAIGN_FEATURED_LIST,
  payload
})

const setCampaignCategories = (payload: CampaignCategory[]): CampaignCategoriesListAction => ({
  type: actionTypes.GET_CAMPAIGN_CATEGORIES,
  payload
})

const setCampaignStatuses = (payload: CampaignStatus[]): CampaignStatusListAction => ({
  type: actionTypes.GET_CAMPAIGN_STATUSES,
  payload
})

const setParticipatePromotion = (payload: CampaignPromotional): ParticipatePromotionAction => ({
  type: actionTypes.PARTICIPATE_PROMOTION,
  payload
})

const setReferralInfo = (payload: CampaignReferral): CampaignReferralAction => ({
  type: actionTypes.GET_REFERRAL,
  payload
})
