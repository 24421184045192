import React, { FC, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import track, { useTracking } from 'react-tracking'
import { Link, useHistory } from 'react-router-dom'
import { Box, FormHelperText, Typography } from '@material-ui/core'
import { RoutePath } from '../../core/routes/route-path'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import './SignUp.scss'
import InviteCodeForm from './SignUpForms/InviteCodeForm'
import AdditionalInformationForm from './SignUpForms/AdditionalInformationForm'
import { useDispatch, useSelector } from 'react-redux'
import { setHomePath, signUp, validateInviteCode } from '../actions'
import { selectConfig } from '../../config/selectors'
import { toggleSignupWelcome } from '../../config/actions'
import { useMediaQuery } from '@react-hook/media-query'
import {
  AUTH_INDICATOR_SIZE,
  EXISTING_EMAIL,
  INVITE_CODE_ERROR,
  INVITE_CODE_WARNING
} from '../constants'
import InviteCodeFormMobileView from './SignUpForms/InviteCodeFormMobileView'
import AdditionalInformationFormMobileView from './SignUpForms/AdditionalInformationFormMobileView'
import SignUpRightPanel from './SignUpRightPanel/SignUpRightPanel'
import InviteCodeValidationFailDialog from './InviteCodeValidationFailDialog'
import _ from 'lodash'
import {
  getUserInfo,
  setHasJustLoggedIn,
  updatePreferredLanguage
} from '../../user/actions'
import Spinner from '../../ui/animation/Spinner/Spinner'
import { parseCssTheme } from '../../core/utils/useTheme'
import { languageService } from '../../language/service'

const SignUp: FC = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch<UserAction> = useDispatch()
  const [inviteCode, setInviteCode] = useState<string>('')
  const [isInviteCodeSubmit, setIsInviteCodeSubmit] = useState<boolean>(false)
  const config: ConfigState = useSelector(selectConfig)
  const history = useHistory()
  const { trackEvent } = useTracking()
  const [error, setError] = useState<string>('')
  const matchesMdw = useMediaQuery('(min-width: 1024px)')
  const matchesMdh = useMediaQuery('(min-height: 680px)')
  const [isSignUpAgain, setIsSignUpAgain] = useState<boolean>(false)
  const [isSignUpWithWarning, setIsSignUpWithWarning] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const customTheme = parseCssTheme(config.CssTheme)

  const handleInviteCodeSubmit = (inviteCode: string): void => {
    setInviteCode(inviteCode)
    if (!_.isEmpty(inviteCode)) {
      dispatch(
        validateInviteCode({ inviteCode, tenantID: config.TenantID ?? '' })
      )
        .then(() => {
          setError('')
          setIsInviteCodeSubmit(true)
        })
        .catch((err: any) => {
          setError(err.message)
        })
    } else {
      setError('')
      setIsInviteCodeSubmit(true)
    }
  }

  const handleDiscard = (): void => {
    setIsInviteCodeSubmit(false)
    setInviteCode('')
    setError('')
  }

  const handleAdditionalInfoSubmit = (info: SignUpForm): void => {
    const userInfo = {
      inviteCode,
      tenantID: config.TenantID ?? '',
      ...info
    }
    trackEvent({
      page: 'SignUp',
      action: 'Complete SignUp'
    })
    setLoading(true)
    dispatch(signUp(userInfo))
      .then((res: any) => {
        if (
          config.SignUpScreenTitle != null &&
          config.SignUpWelcomeDescription != null &&
          config.SignUpWelcomeButtonLabel != null
        ) {
          dispatch(toggleSignupWelcome(true))
        }
        dispatch(setHasJustLoggedIn(true))
        history.push(RoutePath.Home)
        dispatch(getUserInfo())
        if (
          (!matchesMdh || !matchesMdw) &&
          config.SupportLanguage &&
          config.IsWelcomeScreenSelector
        ) {
          dispatch(updatePreferredLanguage(languageService.getLanguage()))
        }
      })
      .catch((err: { [key: string]: string }) => {
        const error = err.message.includes(EXISTING_EMAIL)
          ? t`ExistingEmailErr`
          : err.message
        if (error.includes(INVITE_CODE_ERROR)) {
          setIsSignUpAgain(true)
        } else if (error.includes(INVITE_CODE_WARNING)) {
          setIsSignUpWithWarning(true)
        } else {
          setError(error)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleInviteCodeValidationErrorClose = (): void => {
    setIsInviteCodeSubmit(false)
    setIsSignUpAgain(false)
  }

  const handleInviteCodeValidationWarningClose = (): void => {
    setIsSignUpWithWarning(false)
    dispatch(signUp(null)).then((res: any) => {
      if (
        config.SignUpScreenTitle != null &&
        config.SignUpWelcomeDescription != null &&
        config.SignUpWelcomeButtonLabel != null
      ) {
        dispatch(toggleSignupWelcome(true))
      }
      history.push(RoutePath.Home)
      dispatch(getUserInfo())
    })
  }

  const errorDisplay = (
    <FormHelperText error>{t(error, { inviteCode })}</FormHelperText>
  )

  useEffect(() => {
    dispatch(setHomePath(false))
  }, [dispatch])

  return isLoading ? (
    <Box className='spinner-container'>
      <Box className='spinner-holder'>
        <Spinner height={AUTH_INDICATOR_SIZE} width={AUTH_INDICATOR_SIZE} />
      </Box>
    </Box>
  ) : (
    <Box className='sign-up-container'>
      <Box
        className={`sign-up-left ${
          !matchesMdh || !matchesMdw ? 'overflow' : ''
        }`}
      >
        {matchesMdw && matchesMdh ? (
          <Box className='form-fields-box'>
            <Box className={`tenant-logo ${isInviteCodeSubmit ? 'mb-15' : ''}`}>
              <img src={config.TenantLogoURL ?? ''} alt='tenant-logo' />
            </Box>
            {!isInviteCodeSubmit && (
              <Link to={RoutePath.SignIn} className='back-to-sign-in no-decor'>
                <ArrowBackIcon fontSize='small' className='arrow-responsive' />
                <Typography>{t`GoBackToSignIn`}</Typography>
              </Link>
            )}
            <Typography className='sign-up-heading'>{t`SignUp!`}</Typography>
            {!isInviteCodeSubmit ? (
              <InviteCodeForm
                isInviteCodeDisabled={
                  !config.WelcomeScreenConfig.IsInviteCodeDisplay ?? false
                }
                isInviteCodeMandatory={
                  config.WelcomeScreenConfig.IsInviteCodeMandatory ?? false
                }
                customTheme={customTheme}
                handleInviteCodeSubmit={handleInviteCodeSubmit}
                moreInformationLink={
                  config.WelcomeScreenConfig.MoreInformationLink
                }
                moreInformationLinkDescription={
                  config.WelcomeScreenConfig.MoreInformationLinkDescription
                }
                termsAndConditionsUrl={
                  config.WelcomeScreenConfig.TermsAndConditionsURL ?? ''
                }
                privacyPolicyUrl={
                  config.WelcomeScreenConfig.PrivacyPolicyURL ?? ''
                }
                moreInformationUrl={
                  config.WelcomeScreenConfig.MoreInformationLinkURL ?? ''
                }
              />
            ) : (
              <AdditionalInformationForm
                customTheme={customTheme}
                handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
                handleDiscard={handleDiscard}
              />
            )}
            {error !== '' && errorDisplay}
          </Box>
        ) : (
          <Box
            className={`h-100 mobile-view-box ${
              isInviteCodeSubmit ? 'w-100' : ''
            }`}
          >
            {!isInviteCodeSubmit ? (
              <InviteCodeFormMobileView
                isInviteCodeDisabled={
                  !config.WelcomeScreenConfig.IsInviteCodeDisplay ?? false
                }
                isInviteCodeMandatory={
                  config.WelcomeScreenConfig.IsInviteCodeMandatory ?? false
                }
                customTheme={customTheme}
                handleInviteCodeSubmit={handleInviteCodeSubmit}
                moreInformationLink={
                  config.WelcomeScreenConfig.MoreInformationLink
                }
                moreInformationLinkDescription={
                  config.WelcomeScreenConfig.MoreInformationLinkDescription
                }
                termsAndConditionsUrl={
                  config.WelcomeScreenConfig.TermsAndConditionsURL ?? ''
                }
                privacyPolicyUrl={
                  config.WelcomeScreenConfig.PrivacyPolicyURL ?? ''
                }
                moreInformationUrl={
                  config.WelcomeScreenConfig.MoreInformationLinkURL ?? ''
                }
                errorDisplay={error !== '' ? errorDisplay : null}
              />
            ) : (
              <AdditionalInformationFormMobileView
                customTheme={customTheme}
                handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
                handleDiscard={handleDiscard}
                errorDisplay={error !== '' ? errorDisplay : null}
              />
            )}
          </Box>
        )}
      </Box>
      {matchesMdw && matchesMdh && (
        <SignUpRightPanel customTheme={customTheme} />
      )}
      {isSignUpAgain && (
        <InviteCodeValidationFailDialog
          open={isSignUpAgain}
          handleClose={handleInviteCodeValidationErrorClose}
          content={t`CodeNotAvailableMsg`}
        />
      )}
      {isSignUpWithWarning && (
        <InviteCodeValidationFailDialog
          open={isSignUpWithWarning}
          handleClose={handleInviteCodeValidationWarningClose}
          content={t`SignUpWithWarningMsg`}
        />
      )}
      {isLoading && (
        <Box className='spinner-container'>
          <Box className='spinner-holder'>
            <Spinner height={AUTH_INDICATOR_SIZE} width={AUTH_INDICATOR_SIZE} />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default track({
  page: AnalyticsCategory.SignUp
})(SignUp)
