import * as Sentry from '@sentry/react'

export const isValidUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url, window.location.href))
  } catch (err) {
    Sentry.captureException(err)
    return false
  }
}

export const isExternal = (url: string): boolean => {
  const mainUrl = new URL(window.location.href)
  const compareUrl = new URL(url, window.location.href)
  return mainUrl.hostname !== compareUrl.hostname
}
