import React, { ReactElement } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import '../SignIn/InvalidCredentialsDialog.scss'
import { useTranslation } from 'react-i18next'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

interface InviteCodeValidationFailDialogProps {
  open: boolean
  handleClose: () => void
  content: string
}

const InviteCodeValidationFailDialog = ({
  open,
  handleClose,
  content
}: InviteCodeValidationFailDialogProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='invalid-credentials-dialog'
      maxWidth='xs'
    >
      <ErrorOutlineOutlinedIcon
        className='info-icon'
        color='error'
        fontSize='large'
      />
      <DialogTitle className='title'>
        {t`CodeNotAvailable`}
        <CloseOutlinedIcon
          onClick={handleClose}
          aria-label='close'
          className='close'
        />
      </DialogTitle>
      <DialogContent className='content'>{content}</DialogContent>
      <DialogActions>
        <Button
          id='invalid-code'
          className='btn dialog-btn'
          onClick={handleClose}
        >
          {t`Ok`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InviteCodeValidationFailDialog
