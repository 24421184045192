import { Dispatch } from 'redux'
import amplitude from 'amplitude-js'

import * as actionTypes from './actionTypes'
import { authService, tokenService } from './service'
import {
  agreeTerms,
  clearEarningList,
  clearUserInfo,
  clearUserOfferStatus,
  getEarningList,
  getUserInfo,
  getUserOfferStatus,
  setIsDataLoaded
} from '../user/actions'
import {
  clearCampaignList,
  getCampaignCategories,
  getCampaignFeaturedList,
  getCampaignList,
  getCampaignStatuses,
  getReferralInfo
} from '../campaign/actions'
import {
  clearOfferList,
  getOfferList,
  getOfferCategories
} from '../offer/actions'
import {
  clearProviderList,
  clearProviderStatusList,
  getProviderList,
  getProviderStatusList
} from '../provider/actions'
import { changeTab } from '../config/actions'
import { TabsName } from '../core/tabsName'
import { UseTranslationResponse } from 'react-i18next'
import { EXISTING_EMAIL, INVITE_CODE_WARNING, WRONG_PASSWORD } from './constants'
import { getUpdateCardList } from '../updateCards/actions'

const { REACT_APP_TENANT_ID_FOR_CLIENT } = process.env

export const signIn: any = (data?: SignInForm) => async (dispatch: Dispatch<AuthAction | UserAction>): Promise<any> => {
  const signInSwitcher = async (data?: SignInForm): Promise<boolean | AuthErrorAction> => {
    if (data == null) {
      return await Promise.resolve(true)
    }
    return await authService.signIn(data)
      .then(response => response.success)
      .catch((err) => {
        dispatch(setSignInError(err))
        throw new Error(err.error_description ?? err.message)
      })
  }
  return signInSwitcher(data)
    .then(() => dispatch(getUserInfo()))
    .then((data: UserAction) => amplitude.getInstance().setUserId(data?.payload?.UserID ?? null))
    .then(() => tokenService.setToken())
    .then((flag) => {
      dispatch(setSignIn(true))
      if (data?.isStandalone === true && data?.isReferralAvailable === true) {
        dispatch(getReferralInfo(REACT_APP_TENANT_ID_FOR_CLIENT))
      }
    })
    .then(async () => await Promise.all([
      dispatch(getEarningList()),
      dispatch(getCampaignList()),
      dispatch(getUpdateCardList()),
      dispatch(getCampaignCategories()),
      dispatch(getCampaignStatuses()),
      dispatch(getCampaignFeaturedList()),
      dispatch(getOfferCategories()),
      dispatch(getOfferList()),
      dispatch(getUserOfferStatus()),
      dispatch(getProviderList()),
      dispatch(getProviderStatusList())
    ]))
    .then(() => dispatch(setIsDataLoaded(true)))
    .catch((err) => err?.message ?? err)
}

export const passwordReset: any = (data: PasswordResetForm, properties: TenantPropertiesParams) => async (dispatch: Dispatch<UserAction>): Promise<void> => {
  const passwordReset = async (data: PasswordResetForm, properties: TenantPropertiesParams): Promise<void> => {
    return await authService
      .passwordReset(data, properties)
      .catch((err) => {
        dispatch(setPasswordResetError(err.response.data))
      })
  }
  return passwordReset(data, properties)
}

export const editAccountInfo: any =
  (
    data: EditAccountInfoParams,
    i18t: UseTranslationResponse<'translation', undefined>
  ) =>
    async (dispatch: Dispatch<UserAction>): Promise<void> => {
      const editAccountInfo = async (
        data: EditAccountInfoParams
      ): Promise<void> => {
        return await authService
          .editAccountInfo(data)
          .catch((err: { [key: string]: { [key: string]: string } }) => {
            const error = err.response.data.includes(EXISTING_EMAIL)
              ? i18t.t`ExistingEmailErr`
              : err.response.data
            dispatch(setEditAccountInfoError(error))
            throw new Error(error)
          })
      }
      return editAccountInfo(data)
        .then(() => dispatch(getUserInfo()))
        .catch((err) => err.message)
    }

export const editAccountPassword: any =
  (
    data: EditPasswordParams,
    i18t: UseTranslationResponse<'translation', undefined>
  ) =>
    async (dispatch: Dispatch<UserAction>): Promise<void> => {
      const editAccountPassword = async (
        data: EditPasswordParams
      ): Promise<void> => {
        return await authService
          .editAccountPassword(data)
          .catch((err: { [key: string]: { [key: string]: string } }) => {
            const error = err.response.data.includes(WRONG_PASSWORD)
              ? i18t.t`WrongPasswordErr`
              : err.response.data
            dispatch(setEditAccountPasswordError(error))
          })
      }
      return editAccountPassword(data)
    }

export const signUp: any = (data: SignUpForm) => async (dispatch: Dispatch<UserAction>): Promise<any> => {
  const signUpSwitcher = async (data: SignUpForm): Promise<any> => {
    if (data == null) {
      return await Promise.resolve(true)
    }
    return await authService.signUp(data)
      .then(response => {
        if (response.isInviteCodeInvalid) {
          throw new Error(INVITE_CODE_WARNING)
        }
      })
      .catch((err) => {
        if (err === EXISTING_EMAIL) {
          throw new Error(err)
        }
        throw new Error(err.message)
      })
  }

  return signUpSwitcher(data).then(() => dispatch(getUserInfo()))
    .then((data: UserAction) => amplitude.getInstance().setUserId(data.payload.UserID ?? null))
    .then(() => dispatch(agreeTerms()))
    .then(() => tokenService.setToken())
    .then((flag) => dispatch(setSignIn(true)))
    .then(async () => await Promise.all([
      dispatch(getEarningList()),
      dispatch(getCampaignList()),
      dispatch(getUpdateCardList()),
      dispatch(getCampaignCategories()),
      dispatch(getCampaignStatuses()),
      dispatch(getCampaignFeaturedList()),
      dispatch(getOfferCategories()),
      dispatch(getOfferList()),
      dispatch(getUserOfferStatus()),
      dispatch(getProviderList()),
      dispatch(getProviderStatusList())
    ]))
    .then(() => dispatch(setIsDataLoaded(true)))
    .catch((err) => {
      throw new Error(err.message)
    })
}

export const validateInviteCode: any =
  (data: InviteCodeValidationForm) =>
    async (dispatch: Dispatch<UserAction>): Promise<void> => {
      const validateInviteCode = async (
        data: InviteCodeValidationForm
      ): Promise<any> => {
        return await authService.validateInviteCode(data)
          .catch((err) => {
            throw new Error(err.message)
          })
      }
      return validateInviteCode(data).catch((err) => {
        throw new Error(err.message)
      })
    }

export const signOut: any = () => async (dispatch: Dispatch<AuthAction | SetHomePathAction >): Promise<any> => {
  return await authService.signOut()
    .then(() => tokenService.clearToken())
    .then(() => dispatch(setSignOut(false)))
    .then(() => dispatch(setHomePath(false)))
    .then(async (response) => await Promise.all([
      dispatch(clearUserInfo()),
      dispatch(clearEarningList()),
      dispatch(clearCampaignList()),
      dispatch(clearOfferList()),
      dispatch(clearUserOfferStatus()),
      dispatch(clearProviderList()),
      dispatch(clearProviderStatusList()),
      dispatch(changeTab(TabsName.Earn))
    ]))
    .then(response => {
      amplitude.getInstance().setUserId(null)
      return response
    })
}

export const setSignIn = (isAuth: boolean): AuthAction => ({
  type: actionTypes.SIGN_IN,
  payload: { isAuth }
})

export const setHomePath = (isHomePath: boolean): SetHomePathAction => ({
  type: actionTypes.SET_HOME_PATH,
  payload: { isHomePath }
})

const setSignOut = (isAuth: boolean): AuthAction => ({
  type: actionTypes.SIGN_OUT,
  payload: { isAuth }
})

const setSignInError = (error: AuthError): AuthErrorAction => ({
  type: actionTypes.SIGN_IN_ERROR,
  payload: { error }
})

export const resetSignInError = (error: AuthError): AuthErrorAction => ({
  type: actionTypes.RESET_SIGN_IN_ERROR,
  payload: { error }
})

const setPasswordResetError = (error: string): PasswordResetErrorAction => ({
  type: actionTypes.PASSWORD_RESET_ERROR,
  payload: { error }
})

const setEditAccountInfoError = (
  error: string
): EditAccountInfoErrorAction => ({
  type: actionTypes.EDIT_ACCOUNT_INFO_ERROR,
  payload: { error }
})

const setEditAccountPasswordError = (
  error: string
): EditAccountInfoErrorAction => ({
  type: actionTypes.EDIT_ACCOUNT_PASSWORD_ERROR,
  payload: { error }
})

export const resetPasswordResetError = (
  error?: string
): PasswordResetErrorAction => ({
  type: actionTypes.RESET_PASSWORD_RESET_ERROR,
  payload: { error }
})

export const resetEditAccountInfoError = (
  error?: string
): EditAccountInfoErrorAction => ({
  type: actionTypes.RESET_EDIT_ACCOUNT_INFO_ERROR,
  payload: { error }
})

export const resetEditAccountPasswordError = (
  error?: string
): EditAccountInfoErrorAction => ({
  type: actionTypes.RESET_EDIT_ACCOUNT_PASSWORD_ERROR,
  payload: { error }
})
